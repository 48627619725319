import React, { useEffect, useState } from 'react';
import { NotificationService } from '../../helper/notification';
import {getRoleById, getRoleByIdTR} from '../../api/admin/role';
import { getSubscriptionPlanSelection } from '../../helper/auth';
import i18n from "i18next";
import {useTranslation} from "react-i18next";

const SubscriptionSelection = ({ hasPlan, roleSelection, nextStep, previousStep }) => {
    const {t, i18n} = useTranslation();

    const [selectedPlan, setSelectedPlan] = useState(Number(localStorage.getItem("subscriptionPlanSelection")));
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);

    useEffect(() => {
        const getSubscriptionPlans = async () => {
            try {
                if(i18n.language === 'tr-TR') {
                    const subscriptionPlansData = await getRoleByIdTR(roleSelection);
                    setSubscriptionPlans(subscriptionPlansData);
                }
                else {
                    const subscriptionPlansData = await getRoleById(roleSelection);
                    setSubscriptionPlans(subscriptionPlansData.subscriptionPlanInfoList);
                }
            } catch (error) {
                NotificationService.error("An error occured");
            }

            return subscriptionPlans;
        }

        if (hasPlan !== null) {
            nextStep({ plan: hasPlan });
        } else {
            getSubscriptionPlans();
        }

    }, []);

    const handlePlanChange = (plan) => {
        setSelectedPlan(plan);
    };

    const handleNext = () => {
        if (selectedPlan) {
            localStorage.removeItem("subscriptionPlanSelection");
            nextStep({ plan: selectedPlan });
        }
    };

    return (
        <div className="container ">
            <h2 className="text-center mb-4">{t('select_your_plan')}</h2>
            <div className="row justify-content-center">
                {subscriptionPlans.map((plan) => (
                    plan !== null ? (
                        <div className="col-lg-3 col-md-6 mb-4" key={plan.id}>
                            <div className={`card h-100 shadow-sm ${selectedPlan === plan ? 'border-success' : ''}`} onClick={() => handlePlanChange(plan)}>
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">{plan.name}</h5>
                                    <h6 className="card-subtitle mb-2 text-muted">{plan.description}</h6>
                                    <hr />
                                    <ul className="list-unstyled mb-4 flex-grow-1">
                                        {plan.features.map((feature, index) => (
                                            <li key={index} className="mb-2">
                                                <i className="bi bi-check-circle-fill text-success"></i> {feature}
                                            </li>
                                        ))}
                                    </ul>
                                    <h6 className="card-subtitle mb-2 text-muted">{plan.price}$</h6>
                                </div>
                            </div>
                        </div>) : <></>

                ))}
            </div>
            <div className="d-flex justify-content-between">
                <button className="btn btn-secondary" onClick={previousStep}>Back</button>
                <button className="btn btn-success" onClick={handleNext} disabled={!selectedPlan}>Next</button>
            </div>
        </div >
    );
};

export default SubscriptionSelection;
