import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  fetchAnalyze,
  getProjectConstructionsByConstructionId,
} from "../../../api/construction";
import ClipLoader from "react-spinners/ClipLoader";
import StackedBarChartMaterial from "../../../components/Charts/StackedBarChart/StackedBarChartMaterial";
import { NotificationService } from "../../../helper/notification";
import PieChart from "../../../components/Charts/PieChart/PieChart";
import SankeyChart from "../../../components/Charts/SankeyChart/SankeyChart";
import StackedBarChartFamily from "../../../components/Charts/StackedBarChart/StackedBarChartFamily";
import HorizontalBarChart from "../../../components/Charts/HorizontalBarChart/HorizontalBarChart";
import { getProject } from "../../../api/projects";
import "./Tabbar.css";

import icon_1 from "./analyze-icon-1.png";
import icon_2 from "./analyze-icon-2.png";
import icon_3 from "./analyze-icon-3.png";
import icon_4 from "./analyze-icon-4.png";
import ExportReport from "./AnalyzeReport";
import { getUserDetailed } from "../../../api/auth";
import {useTranslation} from "react-i18next";

const TabBar = ({ tabs, activeTab, setActiveTab }) => (
  <ul className="nav nav-tabs custom-tabbar">
    {tabs.map(({ id, label }) => (
      <li className="nav-item" key={id}>
        <button
          className={`nav-link custom-nav-link ${activeTab === id ? 'active' : ''}`}
          onClick={() => setActiveTab(id)}
        >
          {label}
        </button>
      </li>
    ))}
  </ul>
);

const Dropdown = ({ options, activeOption, setActiveOption }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    setActiveOption(option);
    setIsOpen(false);
  };

  return (
    <div className="dropdown">
      <button
        className="btn btn-outline-dark dropdown-toggle"
        onClick={toggleDropdown}
      >
        {options.find(option => option.id === activeOption).label}
      </button>
      {isOpen && options.map(({ id, label }) => (
        <button
          className="dropdown-item"
          onClick={() => handleSelect(id)}
        >{label}</button>
      ))}
    </div>
  );
}

export const Analyze = () => {


  const [user, setUser] = useState(null);
  const { projectId, constructionId } = useParams();
  const [constructionData, setConstructionData] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [analyzeResult, setAnalyzeResult] = useState(null);
  const [activeInfoTab, setActiveInfoTab] = useState('building-structure');
  const [activeStackedBarChartTab, setActiveStackedBarChartTab] = useState('stacked-bar-material');
  const [activeSecondaryStackedBarChartTab, setActiveSecondaryStackedBarChartTab] = useState('stacked-bar-material');
  const tree = 22;

  const {t, i18n} = useTranslation();


  useEffect(() => {
    const getConstructionData = async () => {
      try {
        const userData = await getUserDetailed();
        setUser(userData);
        const analyzeData = await getProjectConstructionsByConstructionId(projectId, constructionId);
        setConstructionData(analyzeData.data);
      } catch (error) {
        NotificationService.error("An error occurred");
      }
    };

    const getProjectData = async () => {
      try {
        const response = await getProject(projectId);
        setProjectData(response.data);
      } catch (error) {
        NotificationService.error("An error occurred");
      }
    };

    const analyze = async () => {
      try {
        const response = await fetchAnalyze(projectId, constructionId);
        setAnalyzeResult(response.data);
      } catch (error) {
        NotificationService.error("An error occurred");
      }
    };

    getConstructionData();
    getProjectData();
    analyze();
  }, [projectId, constructionId]);

  if (constructionData === null || projectData === null || analyzeResult === null) {
    return (
      <div className="container secondary-bg p-5">
        <div className="mt-5 d-flex justify-content-center align-items-center">
          <ClipLoader
            loading={true}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </div>
    );
  }

  const filteredData = analyzeResult.groupAnalyzeResponses.filter((elem) =>
      elem.name.includes('Co2')
  );



  const constructionInfoSection = (
    <>
      <div className="container px-5">
        <div className="bg-white rounded-4 p-5">
          <span className="secondary-text fw-bold">{t('building')} {projectData.projectName}: {t('building_structure')}</span>
          <div className="pb-4"></div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">{t('family_of_material')}</th>
                <th scope="col">{t('material_name')}</th>
                <th scope="col">{t('quantity')}</th>
              </tr>
            </thead>
            <tbody>
              {constructionData.materials.map((material, index) => (
                <tr key={index}>
                  <td>{material.buildingComponent}</td>
                  <td>{material.type}</td>
                  <td>{material.quantity} ({material.bestGwpMaterialData.unit})</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );

  const co2AnalyzeSection = (
    <>
      {/* Dropdown for Stacked Bar Charts */}
      <div className="container px-5">
        <div className="bg-white rounded-4 p-5">
          <div className="d-flex justify-content-between">
            <span className="secondary-text fw-bold">{t('stacked_bar_charts')}</span>
            <div className="px-3"></div>
            <Dropdown
              options={[
                { id: 'stacked-bar-material', label: t('stacked_bar_charts_material') },
                { id: 'stacked-bar-family', label: t('stacked_bar_charts_family') },
              ]}
              activeOption={activeStackedBarChartTab}
              setActiveOption={setActiveStackedBarChartTab}
            />
          </div>

          {activeStackedBarChartTab === 'stacked-bar-material' && (
            <StackedBarChartMaterial
              header={"CO2 Group Analyze"}
              data={analyzeResult.groupAnalyzeResponses.filter((elem) =>
                elem.name.includes("Co2")
              )}
              dataType={"Co2"}
            />
          )}

          {activeStackedBarChartTab === 'stacked-bar-family' && (
            <StackedBarChartFamily
              header={"CO2 Group Analyze"}
              data={analyzeResult.groupAnalyzeResponses.filter((elem) =>
                elem.name.includes("Co2")
              )}
              dataType={"Co2"}
            />
          )}
        </div>
      </div>

      <div className="py-5"></div>
      {/* Dropdown for Pie and Horizontal Bar Charts */}
      <div className="container px-5">
        <div className="bg-white rounded-4 p-5">
          <div className="d-flex justify-content-start">
            <span className="secondary-text fw-bold">{t('pie_charts')}</span>
          </div>
          <PieChart
            data={analyzeResult.groupAnalyzeResponses.filter((elem) =>
              elem.name.includes("Ener")
            )}
          />
        </div>
      </div>

      <div className="py-5"></div>
      {/* Dropdown for Pie and Horizontal Bar Charts */}
      <div className="container px-5">
        <div className="bg-white rounded-4 p-5">
          <div className="d-flex justify-content-start">
            <span className="secondary-text fw-bold">{t('horizontal_bar_charts')}</span>
          </div>
          <HorizontalBarChart
            data={analyzeResult.groupAnalyzeResponses.filter((elem) =>
              elem.name.includes("Co2")
            )}
          />
        </div>
      </div>
      <div className="py-5"></div>
      <div className="container px-5 pb-3">
        <div className="bg-white rounded-4 p-5">
          <span className="secondary-text fw-bold mb-3">
            CO<sub>2</sub> {t('table')}
          </span>
          <table className="table">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">{t('total_gwp_value')}</th>
                <th scope="col">{t('total_energy_efficiency')}</th>
              </tr>
            </thead>
            <tbody>
              {analyzeResult.groupAnalyzeResponses.map((analyze, index) => {
                let group_name = ""
                if (index === 0) {
                  group_name = t('best_co2')
                }
                else if (index === 1) {
                  group_name = t('median_co2')
                }
                else if (index === 2) {
                  group_name = t('worst_co2')
                }
                if (analyze.name.includes("Co2")) {
                  return (
                    <tr key={index}>
                      <td>{group_name}</td>
                      <td>{analyze.totalCo2Emission}</td>
                      <td>{analyze.totalEnergyEfficiency}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );

  const energyEfficiencyAnalyzeSection = (
    <>
      <div className="container px-5">
        <div className="bg-white rounded-4 p-5">
          <div className="d-flex justify-content-between">
            <span className="secondary-text fw-bold mb-3">{t('energy_efficiency_analyze')}</span>
            <Dropdown
              options={[
                { id: 'stacked-bar-material', label: t('stacked_bar_charts_material') },
                { id: 'stacked-bar-family', label: t('stacked_bar_charts_family') },
              ]}
              activeOption={activeSecondaryStackedBarChartTab}
              setActiveOption={setActiveSecondaryStackedBarChartTab}
            />
          </div>

          {activeSecondaryStackedBarChartTab === 'stacked-bar-material' && (
            <StackedBarChartMaterial
              header={"Energy Efficiency Analyze"}
              data={analyzeResult.groupAnalyzeResponses.filter((elem) =>
                elem.name.includes("Energy")
              )}
              dataType={"Energy"}
            />
          )}

          {activeSecondaryStackedBarChartTab === 'stacked-bar-family' && (
            <StackedBarChartFamily
              header={"Energy Efficiency Analyze"}
              data={analyzeResult.groupAnalyzeResponses.filter((elem) =>
                elem.name.includes("Energy")
              )}
              dataType={"Energy"}
            />
          )}
        </div>
      </div>
      <div className="py-5"></div>
      <div className="container px-5 pb-3">
        <div className="bg-white rounded-4 p-5">
          <span className="secondary-text fw-bold mb-3">{t('energy_efficiency_table')}</span>
          <table className="table">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">{t('total_gwp_value')}</th>
                <th scope="col">{t('total_energy_efficiency')}</th>
              </tr>
            </thead>
            <tbody>
              {analyzeResult.groupAnalyzeResponses.map((analyze, index) => {
                let group_name = ""
                if (analyze.name === "Best EnergyEfficient Group Analyze") {
                  group_name = t('best_energy')
                }
                else if (analyze.name === "Median EnergyEfficient Group Analyze") {
                  group_name = t('median_energy')
                }
                else if (analyze.name === "Worst EnergyEfficient Group Analyze") {
                  group_name = t('worst_energy')
                }
                if (analyze.name.includes("Energy")) {
                  return (
                    <tr key={index}>
                      <td>{group_name}</td>
                      <td>{analyze.totalCo2Emission}</td>
                      <td>{analyze.totalEnergyEfficiency}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );

  return (
    <>
      <body className="bg-body-tertiary">
        <section className="py-5">
          <div className="container p-5">
            <div className="d-flex justify-content-between py-5">
              <h2 className="secondary-text">{t('project_analyze')}</h2>
              {user && (user?.role.permissions?.some(permission => permission.id === 11)
                || user?.subscriptionPlan.subscriptionPlanInfo.permissions?.some(permission => permission.id === 11)) ? (<ExportReport data={analyzeResult} />) : (<></>)}
            </div>
            <TabBar
              tabs={[
                { id: 'building-structure', label: t('building_structure') },
                { id: 'co2-analyze', label: t('co2_analyze') },
                { id: 'energy-efficiency-analyze', label: t('energy_efficiency_analyze') }
              ]}
              activeTab={activeInfoTab}
              setActiveTab={setActiveInfoTab}
            />
            <div className="py-3"></div>
            <div className="border rounded-4 p-3">
              <span className="secondary-text fw-bold">{t('project_overview')}</span>
              <div className="d-flex justify-content-between py-3">
                <div style={{ width: "5px", background: "#127E87" }}></div>
                <img className="d-flex align-self-center" src={icon_1} height={"5%"} width={"5%"} />
                <div className="">
                  <p>{t('building_count')}</p>
                  <h5 className="fw-bold">{projectData.constructions.length}</h5>
                </div>
                <img className="d-flex align-self-center" src={icon_2} height={"5%"} width={"5%"} />
                <div className="">
                  <p>{t('material_count')}</p>
                  <h5 className="fw-bold">{projectData.constructions.reduce((sum, construction) => {
                    return sum + construction.materials.length
                  }, 0)}</h5>
                </div>
                <img className="d-flex align-self-center" src={icon_3} height={"5%"} width={"5%"} />
                <div className="">
                  <p>{t('net_alan')}</p>
                  <h5 className="fw-bold">{projectData.totalArea}</h5>
                </div>
                <img className="d-flex align-self-center" src={icon_4} height={"5%"} width={"5%"} />
                <div className="">
                  <p>{t('number_of_trees')}</p>
                  <h5 className="fw-bold"> ≈ {Math.round((analyzeResult.groupAnalyzeResponses[2].totalCo2Emission - analyzeResult.groupAnalyzeResponses[0].totalCo2Emission) / tree)}</h5>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          {activeInfoTab === 'building-structure' && constructionInfoSection}
          {activeInfoTab === 'co2-analyze' && co2AnalyzeSection}
          {activeInfoTab === 'energy-efficiency-analyze' && energyEfficiencyAnalyzeSection}
        </section>
      </body>
    </>
  );
};
