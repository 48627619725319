import {useEffect, useState} from "react";
import {
  deleteProject,
  getProjects,
  updateProject,
} from "../../../api/projects";
import { addProject } from "../../../api/projects";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { NotificationService } from "../../../helper/notification";
import MultiStepForm from "../../../components/MultiStepForm/MultiStepForm";
import AddressField from "../../../components/ProjectLocation/AddressField";
import { postJoinProject } from "../../../api/projectMembership";
import image_1 from "./project-list-image-1.png";
import image_2 from "./project-list-image-2.png";
import image_3 from "./project-list-image-3.png";
import image_4 from "./project-list-image-4.png";
import image_5 from "./project-list-image-5.png";
import image_6 from "./project-list-image-6.png";
import image_7 from "./project-list-image-7.png";
import image_8 from "./project-list-image-8.png";
import image_9 from "./project-list-image-9.png";
import image_10 from "./project-list-image-10.png";
import image_11 from "./project-list-image-11.png";
import image_12 from "./project-list-image-12.png";
import image_13 from "./project-list-image-13.png";
import image_14 from "./project-list-image-14.png";
import image_15 from "./project-list-image-15.png";
import image_16 from "./project-list-image-16.png";
import image_17 from "./project-list-image-17.png";
import image_18 from "./project-list-image-18.png";
import image_19 from "./project-list-image-19.png";
import image_20 from "./project-list-image-20.png";
import image_21 from "./project-list-image-21.png";
import image_22 from "./project-list-image-22.png";
import image_23 from "./project-list-image-23.png";
import image_24 from "./project-list-image-24.png";
import image_25 from "./project-list-image-25.png";
import image_26 from "./project-list-image-26.png";
import image_27 from "./project-list-image-27.png";
import image_28 from "./project-list-image-28.png";
import image_29 from "./project-list-image-29.png";
import image_30 from "./project-list-image-30.png";
import image_31 from "./project-list-image-31.png";
import image_32 from "./project-list-image-32.png";
import image_33 from "./project-list-image-33.png";
import image_34 from "./project-list-image-34.png";
import image_35 from "./project-list-image-35.png";
import image_36 from "./project-list-image-36.png";
import {getUserDetailed} from "../../../api/auth";
import {useTranslation} from "react-i18next";
import {Spinner} from "react-bootstrap";


const getImage = (index) => {
  const modIndex = index % 36;
  switch (modIndex) {
    case 0:
      return image_1
    case 1:
      return image_2
    case 2:
      return image_3
    case 3:
      return image_4;
    case 4:
      return image_5;
    case 5:
      return image_6;
    case 6:
      return image_7;
    case 7:
      return image_8;
    case 8:
      return image_9;
    case 9:
      return image_10;
    case 10:
      return image_11;
    case 11:
      return image_12;
    case 12:
      return image_13;
    case 13:
      return image_14;
    case 14:
      return image_15;
    case 15:
      return image_16;
    case 16:
      return image_17;
    case 17:
      return image_18;
    case 18:
      return image_19;
    case 19:
      return image_20;
    case 20:
      return image_21;
    case 21:
      return image_22;
    case 22:
      return image_23;
    case 23:
      return image_24;
    case 24:
      return image_25;
    case 25:
      return image_26;
    case 26:
      return image_27;
    case 27:
      return image_28;
    case 28:
      return image_29;
    case 29:
      return image_30;
    case 30:
      return image_31;
    case 31:
      return image_32;
    case 32:
      return image_33;
    case 33:
      return image_34;
    case 34:
      return image_35;
    case 35:
      return image_36;
    default:
      return image_1;
  }
}

export const ProjectList = () => {

  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isModalShown, setIsModalShown] = useState(false);
  const [projects, setProjects] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [address, setAddress] = useState("");
  const [totalArea, setTotalArea] = useState(0);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [updateId, setUpdateId] = useState(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortKey, setSortKey] = useState();
  const [sortOrder, setSortOrder] = useState("asc");
  const [isCardView, setIsCardView] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [refreshProjects, setRefreshProjects] = useState(false); // Yeni state

  const navigate = useNavigate();

  const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    const getProjectList = async () => {
      try {
        if (localStorage.getItem("projectInviteToken") !== null) {
          await postJoinProject({ projectInviteToken: localStorage.getItem("projectInviteToken") });
          localStorage.removeItem("projectInviteToken");
          const projectData = await getProjects();
          setProjects(projectData.data);
        }

      } catch (error) {
        NotificationService.error(error);
      }
    };

    const checkUserIsActive = async () => {
      try {
        const userData = await getUserDetailed();
        if(userData.subscriptionPlan.isActive) {
          await getProjectList();
        }
        else {
          alert("Please renew you subscription plan!")
          console.log("merhaba")
        }
      }
      catch (error) {

      }
    }
    checkUserIsActive();

  }, []);



  const handleFormCompletion = async () => {
    setIsModalOpen(false)
    if (localStorage.getItem("projectInviteToken") === null) {
      try {
        const projectData = await getProjects();

        setProjects(projectData.data);
      } catch (error) {
        NotificationService.error(error);
      }
    }

  };


  const handleFormShown = () => {
    setIsModalShown(true);
  }

  const handleSuggestedAddress = (address) => {
    setAddress(address);
  }

  const goToProjectDetailsPage = (id) => {
    navigate("/projects/" + id);
  };

  const openUpdateModal = (id) => {
    setIsUpdateModalOpen(true);
    setUpdateId(id);
    setProjectName(projects[id].projectName);
    setAddress(projects[id].address);
    setTotalArea(projects[id].totalArea);
  };

  const closeUpdateModal = () => {
    setProjectName("");
    setAddress("");
    setTotalArea(0);
    setIsUpdateModalOpen(false);
  };

  const openDeleteModal = (id) => {
    setIsDeleteModalOpen(true);
    setUpdateId(id);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleCreateProject = async () => {
    try {
      setLoading(true)
      await addProject({ projectName: projectName, address: address, totalArea: totalArea });
      setLoading(false)
      window.location.reload();
    } catch (error) {
      NotificationService.error("An error occurred");
    }
  };

  const handleUpdateProject = async () => {
    try {
      setLoading(true)
      await updateProject(projects[updateId].id, { projectName: projectName, address: address, totalArea: totalArea });
      setLoading(false)
      window.location.reload();
    } catch {
      NotificationService.error("An error occurred");
    }
    setIsUpdateModalOpen(false);
  };

  const handleDeleteProject = async () => {
    try {
      setLoading(true)
      await deleteProject(projects[updateId].id);
      setLoading(false)
      window.location.reload();
    } catch {
      NotificationService.error("An error occurred");
    }
    setIsDeleteModalOpen(false);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleSort = (key) => {
    setSortKey(key);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const getSortIcon = (key) => {
    if (sortKey === key) {
      return sortOrder === "asc" ? (
          <i class="bi bi-sort-down-alt"></i>
      ) : (
          <i class="bi bi-sort-up"></i>
      );
    }
    return null;
  };

  const sortedAndFilteredProjects = projects
      ?.filter(
          (project) =>
              project.projectName.toLowerCase().includes(searchTerm) ||
              project.id.toString().includes(searchTerm)
      )
      ?.sort((a, b) => {
        let aValue = a[sortKey];
        let bValue = b[sortKey];

        if (sortKey === "lastUpdateDate") {
          aValue = new Date(aValue);
          bValue = new Date(bValue);
        }

        if (sortKey === "constructions?.length") {
          aValue = a.constructions?.length || 0;
          bValue = b.constructions?.length || 0;
        }

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortOrder === "asc"
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
        }

        if (typeof aValue === "number" || aValue instanceof Date) {
          return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
        }

        return 0;
      });

  const indexOfLastProject = currentPage * itemsPerPage;
  const indexOfFirstProject = indexOfLastProject - itemsPerPage;
  const currentProjects = sortedAndFilteredProjects === undefined ? null : sortedAndFilteredProjects.slice(indexOfFirstProject, indexOfLastProject);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(sortedAndFilteredProjects === undefined ? null : sortedAndFilteredProjects.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(currentPage + pageNumber);
  };

  const handleItemPerPageChange = (itemCount) => {
    setItemsPerPage(itemCount);
  }


  return (
      <>
        <div className="py-5"></div>
        <section>
          <div className="container mt-5">
            <div className="d-flex justify-content-start align-items-center mb-4">
              <h2 className="">{t('your_projects')}</h2>
            </div>
            <div className="d-flex justify-content-between">
              <div className="input-group w-75 mx-1 my-3">
                <span className="input-group-text bg-transparent"><i className="bi bi-search"></i></span>
                <input
                    type="text"
                    placeholder="Search"

                    value={searchTerm}
                    onChange={handleSearch}
                    className="form-control border-start-0"
                />
              </div>
              <div className="d-flex">
                <button
                    type="button"
                    className="btn secondary-btn rounded-3 mx-1 my-3 px-4"
                    data-bs-toggle="modal"
                    data-bs-target="#addNewProjectModal"
                >
                  {t('add_new_project')} <i className="bi bi-plus"></i>
                </button>
                <h3 className="mx-1 my-3 px-2">
                  <i
                      class={`bi ${!isCardView ? "secondary-text" : "text-secondary"} bi-distribute-vertical`}
                      onClick={() => setIsCardView(false)}
                  />
                </h3>
                <h3 className="mx-1 my-3 px-2">
                  <i
                      class={`bi ${isCardView ? "secondary-text" : "text-secondary"} bi-grid-1x2-fill`}
                      onClick={() => setIsCardView(true)}
                  />
                </h3>
              </div>
            </div>

            {projects == null ? (

                <div className="mt-5 d-flex justify-content-center align-items-center">
                  <ClipLoader
                      loading={projects === null}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                  />
                </div>
            ) : currentProjects.length === 0 ? (
                <div className="alert alert-info" role="alert">
                  {t('no_projects_found')}
                </div>
            ) : isCardView ? (
                <div className="row">
                  {currentProjects.map((project, index) => (
                      <div className="col-md-4" key={project.id}>
                        <div className="card rounded-5 mb-4">
                          <div className="image-container">
                            <img src={getImage(index)} width={"100%"} />
                            <div className="text-overlay rounded-3 ms-3 mt-3" style={{ backgroundColor: "#E7FDFF", color: "#127E87" }}>{project.constructions?.length} Buildings</div>
                          </div>
                          <div
                              className="card-body"
                              onClick={() => goToProjectDetailsPage(project.id)}>
                            <h5 className="card-title">{project.projectName}</h5>
                            <p className="card-text">{t('project_id')} : {project.id}</p>
                            <p className="card-text">{t('address')} :  {project.address}</p>
                            <p className="card-text">{t('total_area')} :  {project.totalArea}</p>
                            <p className="card-text">
                              {t('last_updated_date')} :  {project.lastUpdateDate}
                            </p>
                          </div>
                          <div className="d-flex justify-content-around mb-2">
                            <div
                                className="btn btn-outline-success px-5"
                                onClick={() => openUpdateModal(index)}>
                              <i
                                  className="bi bi-pencil-square"
                              ></i>
                            </div>
                            <div
                                className="btn btn-outline-danger px-5"
                                onClick={() => openDeleteModal(index)}>
                              <i
                                  className="bi bi-trash"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                  ))}
                </div>
            ) : (
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                    <tr>
                      <th scope="col" onClick={() => handleSort("id")}>
                        {t('project_id')} {getSortIcon("id")}
                      </th>
                      <th scope="col" onClick={() => handleSort("projectName")}>
                        {t('project_name')} {getSortIcon("projectName")}
                      </th>
                      <th scope="col" onClick={() => handleSort("address")}>
                        {t('address')} {getSortIcon("address")}
                      </th>
                      <th scope="col" onClick={() => handleSort("totalArea")}>
                        {t('total_area')} {getSortIcon("totalArea")}
                      </th>
                      <th scope="col" onClick={() => handleSort("constructions?.length")}>
                        {t('building_count')} {getSortIcon("constructions?.length")}
                      </th>
                      <th scope="col" onClick={() => handleSort("lastUpdateDate")}>
                        {t('last_updated_date')} {getSortIcon("lastUpdateDate")}
                      </th>
                      <th scope="col">{t('actions')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {currentProjects.map((project, index) => (
                        <tr key={project.id}>
                          <th>{project.id}</th>
                          <td className="col-2">
                            <img className="rounded-4 d-none d-md-inline" src={getImage(index)} height={"30%"} width={"30%"} /> &nbsp;
                            {project.projectName}
                          </td>
                          <td>{project.address}</td>
                          <td>{project.totalArea}</td>
                          <td>{project.constructions?.length}</td>
                          <td>{project.lastUpdateDate}</td>
                          <td>
                            <i
                                onClick={() => openUpdateModal((currentPage - 1) * itemsPerPage + index)}
                                className="bi bi-pencil-square text-success pe-2"
                            ></i>
                            <i
                                onClick={() => openDeleteModal((currentPage - 1) * itemsPerPage + index)}
                                className="bi bi-trash text-danger pe-2"
                            ></i>
                            <i
                                onClick={() => goToProjectDetailsPage(project.id)}
                                className="bi bi-box-arrow-in-right text-primary pe-2"
                            ></i>
                          </td>
                        </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
            )}
            <div className="pagination d-flex justify-content-center py-5">
              <div className="px-3">
                <div className="input-group">
                  <label className="px-2 py-2"> {t('items_per_page')}</label>
                  <select
                      className="form-select border-0 border-2 border-bottom border-dark"
                      value={itemsPerPage}
                      onChange={(event) => { handleItemPerPageChange(event.target.value) }}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </select>
                  <hr />
                </div>
              </div>
              <div className="px-3 py-2">{indexOfFirstProject + 1} - {indexOfLastProject} &nbsp; of &nbsp; {sortedAndFilteredProjects === undefined ? null : sortedAndFilteredProjects.length}</div>
              <button
                  className="btn border-0 mx-3"
                  onClick={() => handlePageChange(-1)}
                  disabled={currentPage <= 1}>
                <i className="bi bi-arrow-left"></i></button>
              <button
                  className="btn border-0 mx-3"
                  onClick={() => handlePageChange(1)}
                  disabled={currentPage >= (sortedAndFilteredProjects === undefined ? null : sortedAndFilteredProjects.length / itemsPerPage)}>
                <i className="bi bi-arrow-right"></i></button>
            </div>
            <div
                className="modal fade"
                id="addNewProjectModal"
                tabIndex="-1"
                aria-labelledby="addNewProjectModalLabel"
                aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="addNewProjectModalLabel">
                      {t('new_project')}
                    </h1>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="py-2">
                      <label>{t('project_name')}</label>
                      <input
                          type="name"
                          className="form-control shadow-none"
                          id="name"
                          onChange={(event) => setProjectName(event.target.value)}
                      />
                    </div>
                    <div className="py-2">
                      <label>{t('address')}</label>
                      <AddressField address={address} suggestedAddress={handleSuggestedAddress} />
                    </div>
                    <div className="py-2">
                      <label>{t('total_area')}</label>
                      <div className="input-group">
                        <input
                            type="number"
                            className="form-control shadow-none"
                            id="name"
                            onChange={(event) => setTotalArea(event.target.value)}
                        />
                        <div className="input-group-append">
                        <span
                            className="input-group-text"
                            dangerouslySetInnerHTML={{
                              __html: "m2".replace(/(\d)/, "<sup>$1</sup>")
                            }}
                        />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                    >
                      {t('cancel')}
                    </button>
                    <button
                        type="button"
                        className="btn secondary-btn"
                        data-bs-dismiss="modal"
                        disabled={projectName === "" || address === "" || totalArea === "" || loading}
                        onClick={handleCreateProject}
                    >
                      {loading ? <Spinner animation="border" size="sm" /> : t("create_project")} {/* Spinner */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {
                isModalOpen && (
                    <div
                        className={`modal fade ${isModalShown ? "show" : "hidden"} d-block`}

                        tabIndex="-1"
                        aria-labelledby="multiStepFormModal"
                        aria-hidden="true"
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                    >
                      <div className="modal-dialog modal-xl modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-body">
                            <MultiStepForm onCompletion={handleFormCompletion} onProcess={handleFormShown} />
                          </div>
                        </div>
                      </div>
                    </div>
                )
            }
            {
                isUpdateModalOpen && (
                    <div
                        className="modal fade show d-block"
                        id="updateProjectModal"
                        tabIndex="-1"
                        aria-labelledby="addNewProjectModalLabel"
                        aria-hidden="true"
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h1 className="modal-title fs-5" id="addNewProjectModalLabel">
                              {t('update')} {projects[updateId].projectName}
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={closeUpdateModal}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <div className="py-2">
                              <label>{t('project_name')}</label>
                              <input
                                  type="name"
                                  className="form-control shadow-none"
                                  id="name"
                                  value={projectName}
                                  onChange={(event) => setProjectName(event.target.value)}
                              />
                            </div>
                            <div className="py-2">
                              <label>{t('address')}</label>
                              <AddressField address={address} suggestedAddress={handleSuggestedAddress} />
                            </div>
                            <div className="py-2">
                              <label>{t('total_area')}</label>
                              <div className="input-group">
                                <input
                                    type="number"
                                    className="form-control shadow-none"
                                    id="name"
                                    value={totalArea}
                                    onChange={(event) => setTotalArea(event.target.value)}
                                />
                                <div className="input-group-append">
                            <span
                                className="input-group-text"
                                dangerouslySetInnerHTML={{
                                  __html: "m2".replace(/(\d)/, "<sup>$1</sup>")
                                }}
                            />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                onClick={closeUpdateModal}
                            >
                              {t('cancel')}
                            </button>
                            <button
                                type="submit"
                                className="btn secondary-btn"
                                data-bs-dismiss="modal"
                                disabled={(projectName === projects[updateId].projectName || projectName === "")
                                    && (address === projects[updateId].address || address === "")
                                    && (totalArea === projects[updateId].totalArea || totalArea === "") || loading}
                                onClick={handleUpdateProject}
                            >
                              {loading ? <Spinner animation="border" size="sm" /> : t("update")} {/* Spinner */}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                )
            }
            {
                isDeleteModalOpen && (
                    <div className="modal fade show d-block" tabIndex="-1" role="dialog">
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">
                              {t('delete')} {projects[updateId].projectName}
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={closeDeleteModal}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <p>{t('delete_description')}</p>
                          </div>
                          <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={closeDeleteModal}
                            >
                              {t('cancel')}
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={handleDeleteProject}
                                disabled={loading}
                            >
                              {loading ? <Spinner animation="border" size="sm" /> : t("delete")} {/* Spinner */}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                )
            }
          </div >
        </section >
      </>
  );
};
