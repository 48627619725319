import React, { useRef } from "react";
import { Pie } from "react-chartjs-2";
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
} from "chart.js";
import "./PieChart.css";
import {useTranslation} from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ data }) => {
    let graphTitle;


    const {t, i18n} = useTranslation();


    const chartRefs = useRef([]);

    const predefinedColors = [
        "#127E87",
        "#A5A5A5",
        "#223E54",

    ];

    const borderColors = predefinedColors.map(color => color.replace(/0\.5\)/, "1)"));

    const handleExport = () => {
        chartRefs.current.forEach((chartRef, index) => {
            if (chartRef) {
                const url = chartRef.toBase64Image();
                const link = document.createElement('a');
                link.href = url;
                link.download = `PieChart-${index + 1}.png`;
                link.click();
            }
        });
    };

    return (
        <>
            <div className="d-flex justify-content-end pt-3">
                <button className="btn btn-outline-dark" onClick={handleExport}>
                    <i className="bi bi-box-arrow-right" /> &nbsp; {t('export_graph')}
                </button>
            </div>

            <div className="pie-charts-container">
                {data.map((group, groupIndex) => {
                    const uniqueTypes = [
                        ...new Set(
                            group.buildingComponentAnalyzeResponses.flatMap((se) =>
                                se.buildingComponent
                            )
                        ),
                    ];

                    const totalEmissionsByType = uniqueTypes.map((type) => {
                        return group.buildingComponentAnalyzeResponses.reduce((sum, se) => {
                            const typeData = se.buildingComponent === type;
                            return sum + (typeData ? se.totalCo2Emission : 0);
                        }, 0);
                    });

                    const pieChartData = {
                        labels: uniqueTypes,
                        datasets: [
                            {
                                label: "CO2 Emission (kgCO2-eq)",
                                data: totalEmissionsByType,
                                backgroundColor: uniqueTypes.map((_, index) => predefinedColors[index % predefinedColors.length]),
                                borderColor: uniqueTypes.map((_, index) => borderColors[index % borderColors.length]),
                                borderWidth: 1,
                            },
                        ],
                    };

                    if(groupIndex===0) {
                        graphTitle = t('best_co2')
                    }
                    else if (groupIndex===1) {
                        graphTitle = t('median_co2')
                    }
                    else if (groupIndex===2) {
                        graphTitle = t('worst_co2')
                    }

                    const options = {
                        plugins: {
                            title: {
                                display: true,
                                text: `${graphTitle} - ${t('co2_emission_by_type')}`,
                            },
                            tooltip: {
                                callbacks: {
                                    label: function (tooltipItem) {
                                        return `${tooltipItem.label}: ${tooltipItem.raw.toFixed(2)} kgCO2-eq`;
                                    },
                                },
                            },
                            legend: {
                                position: "top",
                            },
                        },
                        responsive: true,
                    };

                    return (
                        <div key={groupIndex} className="pie-chart-item">
                            <Pie ref={(el) => (chartRefs.current[groupIndex] = el)} data={pieChartData} options={options} />
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default PieChart;
