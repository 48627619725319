import { Banner } from "../../../../components/LandingPageComponents/Banner/Banner";
import image_1 from "./researcher-image-2.webp";
import image_1_tr from "./researcher-image-2-tr.webp";
import icon_1 from "./researcher-icon-1.webp";
import icon_2 from "./researcher-icon-2.webp";
import icon_3 from "./researcher-icon-3.webp";
import "./Background.css";
import { useNavigate } from "react-router-dom";
import { InsideAction } from "../../../../components/LandingPageComponents/InsideAction/InsideAction";
import {useTranslation} from "react-i18next";

const Researcher = () => {
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    return (
        <>
            <section>
                <Banner startPage={"pricing"} page={"researcher"} />
            </section>
            <section className="py-3">
                <div className="container">
                    <h4 className="secondary-text">{t('roles')}</h4>
                    <div className="row d-flex justify-content-between">
                        <h2 className="col-md-5">{t('researcher')}</h2>
                        <div className="col-md-7">
                            <div className="d-flex justify-content-between border-bottom border-2 border-dark mb-3">
                                <h4>{t('whatsInclude')} <span className="secondary-text">{t('include')}?</span></h4>
                                <i className="bi bi-arrow-right" />
                            </div>
                            <p className="col-9">{t('packagesResearcher')}</p>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-around">
                        <div className="col-md-4">
                            <p className="col-9 pb-3">{t('trueBuildingPerformanceModelling')}</p>
                            {i18n.language === "en" ? (
                                    <img src={image_1} width={"100%"}/>

                                ) :
                                <img src={image_1_tr} width={"100%"}/>

                            }
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex pt-5 pb-3">
                                <img src={icon_1} width={"3%"} /> &nbsp;&nbsp; {t('accessEPD')}
                            </div>
                            <div className="d-flex pb-3">
                                <img src={icon_2} width={"3%"} /> &nbsp;&nbsp; {t('accessArticles')}
                            </div>
                            <div className="d-flex pb-3">
                                <img src={icon_3} width={"3%"} /> &nbsp;&nbsp; {t('comparativeReports')}
                            </div>
                            <button className="btn secondary-btn rounded-0 p-2 px-4 my-3"><a
                                className={"text-white text-decoration-none"}
                                href={"/sign-up"}> {t('become_member')} &nbsp;<i className="bi bi-arrow-right"/></a>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            {/*<section className="py-5">*/}
            {/*    <div className="container">*/}
            {/*        <h4 className="secondary-text text-center pb-2">{t('pricingPlans')}</h4>*/}
            {/*        <h2 className="text-center pb-5">{t('choosePlan')}</h2>*/}
            {/*        <div className="row justify-content-center">*/}
            {/*            <div className="row d-flex justify-content-center mb-4">*/}
            {/*                <div className="card col-md-3 shadow-sm rounded-0 border-0 shadow-none card-bg mx-2 mb-3">*/}
            {/*                    <div className="card-body d-flex flex-column">*/}
            {/*                        <h5 className="card-title text-center">{t('free')}</h5>*/}
            {/*                        <h6 className="card-subtitle text-center mb-4 text-muted">{t('startFreeTrial')}</h6>*/}
            {/*                        <ul className="list-unstyled mb-4 flex-grow-1">*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('readOnly')}*/}
            {/*                            </li>*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('contact24_7')}*/}
            {/*                            </li>*/}
            {/*                            <li className="text-dark text-opacity-25 mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill" /> {t('carbonEmissionAnalysis')}*/}
            {/*                            </li>*/}
            {/*                            <li className="text-dark text-opacity-25 mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill" /> {t('databaseAndAPI')}*/}
            {/*                            </li>*/}
            {/*                            <li className="text-dark text-opacity-25 mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill" /> {t('comparativeAnalysis')}*/}
            {/*                            </li>*/}
            {/*                        </ul>*/}
            {/*                        <button*/}
            {/*                            className="btn secondary-text rounded-0 fw-bold"*/}
            {/*                            onClick={() => navigate("/sign-up")}*/}
            {/*                        >*/}
            {/*                            {t('joinNow')} &nbsp;&nbsp;&nbsp;<i className="bi bi-arrow-right" />*/}
            {/*                        </button>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="card col-md-3 shadow-sm rounded-0 border-0 shadow-none card-bg mx-2 mb-3">*/}
            {/*                    <div className="card-body d-flex flex-column">*/}
            {/*                        <h5 className="card-title text-center">{t('basic')}</h5>*/}
            {/*                        <h6 className="card-subtitle text-center mb-4 text-muted">{t('bestPackage')}</h6>*/}
            {/*                        <ul className="list-unstyled mb-4 flex-grow-1">*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('singleUser')}*/}
            {/*                            </li>*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('contact24_7')}*/}
            {/*                            </li>*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('carbonEmissionAnalysis')}*/}
            {/*                            </li>*/}
            {/*                            <li className="text-dark text-opacity-25 mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill" /> {t('databaseAndAPI')}*/}
            {/*                            </li>*/}
            {/*                            <li className="text-dark text-opacity-25 mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill" /> {t('comparativeAnalysis')}*/}
            {/*                            </li>*/}
            {/*                            <hr />*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('accessArticles')}*/}
            {/*                            </li>*/}
            {/*                            <li className="text-dark text-opacity-25 mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill" /> {t('accessEPD')}*/}
            {/*                            </li>*/}
            {/*                            <li className="text-dark text-opacity-25 mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill" /> {t('comparativeReports')}*/}
            {/*                            </li>*/}
            {/*                        </ul>*/}
            {/*                        <button*/}
            {/*                            className="btn secondary-text rounded-0 fw-bold"*/}
            {/*                            onClick={() => navigate("/sign-up")}*/}
            {/*                        >*/}
            {/*                            {t('joinNow')} &nbsp;&nbsp;&nbsp;<i className="bi bi-arrow-right" />*/}
            {/*                        </button>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="card col-md-3 shadow-sm rounded-0 border-0 shadow-none card-bg mx-2 mb-3">*/}
            {/*                    <div className="card-body d-flex flex-column">*/}
            {/*                        <h5 className="card-title text-center">{t('professional')}</h5>*/}
            {/*                        <h6 className="card-subtitle text-center mb-4 text-muted">{t('bestForProfessional')}</h6>*/}
            {/*                        <ul className="list-unstyled mb-4 flex-grow-1">*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('multipleUser')}*/}
            {/*                            </li>*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('contact24_7')}*/}
            {/*                            </li>*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('carbonEmissionAnalysis')}*/}
            {/*                            </li>*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('databaseAndAPI')}*/}
            {/*                            </li>*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('comparativeAnalysis')}*/}
            {/*                            </li>*/}
            {/*                            <hr />*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('accessArticles')}*/}
            {/*                            </li>*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('accessEPD')}*/}
            {/*                            </li>*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('comparativeReports')}*/}
            {/*                            </li>*/}
            {/*                        </ul>*/}
            {/*                        <button*/}
            {/*                            className="btn secondary-text rounded-0 fw-bold"*/}
            {/*                            onClick={() => navigate("/sign-up")}*/}
            {/*                        >*/}
            {/*                            {t('joinNow')} &nbsp;&nbsp;&nbsp;<i className="bi bi-arrow-right" />*/}
            {/*                        </button>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
            <section className="py-5">
                <InsideAction />
            </section>
        </>
    );
}

export default Researcher;