import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { signUpProcess } from "../../../api/auth";
import { getRoleSelection, getSubscriptionPlanSelection } from "../../../helper/auth";
import image from "../auth-image-2.png";
import {Spinner} from "react-bootstrap";

const SignUp = () => {
    const { t } = useTranslation();
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [reenterPassword, setReEnterPassword] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false); // Loading state


    const navigate = useNavigate();

    const handleSignUp = async () => {
        if (password !== reenterPassword) {
            setError(t("signUp.error.passwordMismatch"));
            return;
        }

        if (!validatePassword(password)) {
            setError(t("signUp.error.passwordInvalid"));
            return;
        }

        if (!validateEmail(email)) {
            setError(t("signUp.error.emailInvalid"));
            return;
        }

        const roleSelection = getRoleSelection();
        const subscriptionPlanSelection = getSubscriptionPlanSelection();

        localStorage.setItem("email", email);
        localStorage.setItem("password", password);
        setLoading(true); // Loading başlıyor

        try {
            await signUpProcess({
                firstname: name,
                lastname: surname,
                email,
                password,
                roleId: roleSelection,
                subscriptionPlanInfoId: subscriptionPlanSelection,
                companyName: companyName
            });

            localStorage.removeItem("roleSelection");
            navigate("/verification-request");
        } catch (error) {
            setError(error);
        }
        setLoading(false); // Loading bitiyor

    };

    const validatePassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/;
        return regex.test(password);
    };

    const validateEmail = (email) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    };

    return (
        <div className="row mb-5 align-items-center">
            <div className="col-md-1"></div>
            <div className="col-md-5 mt-5 mb-5 p-4 align-items-center justify-content-center">
                <h2 className="text-dark mb-3 mt-5">{t("signUp.title")}</h2>
                <p className="text-dark pb-4">{t("signUp.description")}</p>
                {error && <div className="alert alert-danger">{error}</div>}
                <div className="form-group">
                    <div className="d-flex mb-3">
                        <input
                            type="name"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            className="form-control shadow-none me-3"
                            placeholder={t("signUp.namePlaceholder")}
                        />
                        <input
                            type="surname"
                            value={surname}
                            onChange={(event) => setSurname(event.target.value)}
                            className="form-control shadow-none ms-3"
                            placeholder={t("signUp.surnamePlaceholder")}
                        />
                    </div>
                    <div className="d-flex mb-3">
                        <input
                            type="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            className="form-control shadow-none me-3"
                            placeholder={t("signUp.emailPlaceholder")}
                        />
                        <input
                            type="text"
                            value={companyName}
                            onChange={(event) => setCompanyName(event.target.value)}
                            className="form-control shadow-none ms-3"
                            placeholder={t("signUp.companyNamePlaceholder")}
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            className="form-control shadow-none"
                            placeholder={t("signUp.passwordPlaceholder")}
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="password"
                            value={reenterPassword}
                            onChange={(event) => setReEnterPassword(event.target.value)}
                            className="form-control shadow-none"
                            placeholder={t("signUp.reenterPasswordPlaceholder")}
                        />
                    </div>

                    <button
                        className="btn secondary-btn w-100 mt-3 mb-2"
                        onClick={handleSignUp}
                        disabled={loading} // Buton, loading sırasında devre dışı
                    >
                        {loading ? <Spinner animation="border" size="sm"/> : t("signUp.buttonTextt")} {/* Spinner */}
                    </button>
                    <div className="text-dark text-center fs-6" style={{fontSize: "12px"}}>
                        {t("signUp.haveAccount")} &nbsp;
                        <a href="/login" className="text-danger">
                            {t("signUp.login")}
                        </a>
                    </div>
                </div>
            </div>
            <div className="col-md-5 mt-5 mb-5 align-items-center justify-content-center">
                <img src={image} width="100%" alt="Sign Up"/>
            </div>
            <div className="col-md-1"></div>
        </div>
    );
};

export default SignUp;
