import { Banner } from "../../../../components/LandingPageComponents/Banner/Banner";
import image_1 from "./architect-and-engineer-image-2.webp";
import image_1_tr from "./architect-and-engineer-image-2-tr.webp";
import icon_1 from "./architect-and-engineer-icon-1.webp";
import icon_2 from "./architect-and-engineer-icon-2.webp";
import icon_3 from "./architect-and-engineer-icon-3.webp";
import icon_4 from "./architect-and-engineer-icon-4.webp";
import "./Background.css";
import { useNavigate } from "react-router-dom";
import { InsideAction } from "../../../../components/LandingPageComponents/InsideAction/InsideAction";
import {useTranslation} from "react-i18next";

const ArchitectAndEngineer = () => {
    const navigate = useNavigate();

    const {t, i18n} = useTranslation();


    return (
        <>
            <section>
                <Banner startPage={"pricing"} page={"architect-and-engineer"} />
            </section>
            <section className="py-3">
                <div className="container">
                    <h4 className="secondary-text">{t('roles')}</h4>
                    <div className="row d-flex justify-content-between">
                        <h2 className="col-md-5">{t('architect_and_engineer')}</h2>
                        <div className="col-md-7">
                            <div className="d-flex justify-content-between border-bottom border-2 border-dark mb-3">
                                <h4>{t("whats_include")} <span className="secondary-text">{t('include')}</span></h4>
                                <i className="bi bi-arrow-right" />
                            </div>
                            <p className="col-9">{t('package_description')}</p>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-around">
                        <div className="col-md-4">
                            <p className="col-9 pb-3">{t('eco_friendly_description')}</p>
                            {i18n.language === "en" ? (
                                    <img src={image_1} width={"100%"} alt={t('carbon_analysis_image_alt')}/>

                                ) :
                                <img src={image_1_tr} width={"100%"} alt={t('carbon_analysis_image_alt')}/>

                            }
                        </div>
                        <div className="col-md-6">
                        <div className="d-flex pt-5 pb-3">
                                <img src={icon_1} width={"3%"} alt={t('low_carbon_design_guidance_icon_alt')} /> &nbsp;&nbsp; {t('low_carbon_design_guidance')}
                            </div>
                            <div className="d-flex pb-3">
                                <img src={icon_2} width={"3%"} alt={t('right_material_icon_alt')} /> &nbsp;&nbsp; {t('choosing_right_material')}
                            </div>
                            <div className="d-flex pb-3">
                                <img src={icon_3} width={"3%"} alt={t('energy_intensity_icon_alt')} /> &nbsp;&nbsp; {t('energy_intensity_calculating')}
                            </div>
                            <div className="d-flex pb-4">
                                <img src={icon_4} width={"3%"} alt={t('modelling_integration_icon_alt')} /> &nbsp;&nbsp; {t('modelling_integration')}
                            </div>
                            <button className="btn secondary-btn rounded-0 p-2 px-4 my-3"> <a className={"text-white text-decoration-none"} href={"/sign-up"}> {t('become_member')} &nbsp;<i className="bi bi-arrow-right" /></a> </button>
                        </div>
                    </div>
                </div>
            </section>
            {/*<section className="py-5">*/}
            {/*    <div className="container">*/}
            {/*        <h4 className="secondary-text text-center pb-2">{t('pricing_plans')}</h4>*/}
            {/*        <h2 className="text-center pb-5">{t('choose_plan')}</h2>*/}
            {/*        <div className="row justify-content-center">*/}
            {/*            <div className="row d-flex justify-content-center mb-4">*/}
            {/*                <div className="card col-md-3 shadow-sm rounded-0 border-0 shadow-none card-bg mx-2 mb-3">*/}
            {/*                    <div className="card-body d-flex flex-column">*/}
            {/*                        <h5 className="card-title text-center">{t('free')}</h5>*/}
            {/*                        <h6 className="card-subtitle text-center mb-4 text-muted">{t('free_trial_description')}</h6>*/}
            {/*                        <ul className="list-unstyled mb-4 flex-grow-1">*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('read_only')}*/}
            {/*                            </li>*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('contact_24_7')}*/}
            {/*                            </li>*/}
            {/*                            <li className="text-dark text-opacity-25 mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill" /> {t('carbon_emission_analysis')}*/}
            {/*                            </li>*/}
            {/*                            <li className="text-dark text-opacity-25 mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill" /> {t('database_api_access')}*/}
            {/*                            </li>*/}
            {/*                            <li className="text-dark text-opacity-25 mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill" /> {t('comparative_analysis')}*/}
            {/*                            </li>*/}
            {/*                        </ul>*/}
            {/*                        <button*/}
            {/*                            className="btn secondary-text rounded-0 fw-bold"*/}
            {/*                            onClick={() => navigate("/sign-up")}*/}
            {/*                        >*/}
            {/*                            {t('join_now')} &nbsp;&nbsp;&nbsp;<i className="bi bi-arrow-right" />*/}
            {/*                        </button>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="card col-md-3 shadow-sm rounded-0 border-0 shadow-none card-bg mx-2 mb-3">*/}
            {/*                    <div className="card-body d-flex flex-column">*/}
            {/*                        <h5 className="card-title text-center">{t('basic')}</h5>*/}
            {/*                        <h6 className="card-subtitle text-center mb-4 text-muted">{t('basic_package_description')}</h6>*/}
            {/*                        <ul className="list-unstyled mb-4 flex-grow-1">*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('single_user')}*/}
            {/*                            </li>*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('contact_24_7')}*/}
            {/*                            </li>*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('carbon_emission_analysis')}*/}
            {/*                            </li>*/}
            {/*                            <li className="text-dark text-opacity-25 mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill" /> {t('database_api_access')}*/}
            {/*                            </li>*/}
            {/*                            <li className="text-dark text-opacity-25 mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill" /> {t('comparative_analysis')}*/}
            {/*                            </li>*/}
            {/*                            <hr />*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('low_carbon_design_guidance')}*/}
            {/*                            </li>*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('choosing_right_material')}*/}
            {/*                            </li>*/}
            {/*                            <li className="text-dark text-opacity-25 mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill" /> {t('energy_intensity_calculating')}*/}
            {/*                            </li>*/}
            {/*                            <li className="text-dark text-opacity-25 mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill" /> {t('modelling_integration')}*/}
            {/*                            </li>*/}
            {/*                        </ul>*/}
            {/*                        <button*/}
            {/*                            className="btn secondary-text rounded-0 fw-bold"*/}
            {/*                            onClick={() => navigate("/sign-up")}*/}
            {/*                        >*/}
            {/*                            {t('join_now')} &nbsp;&nbsp;&nbsp;<i className="bi bi-arrow-right" />*/}
            {/*                        </button>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="card col-md-3 shadow-sm rounded-0 border-0 shadow-none card-bg mx-2 mb-3">*/}
            {/*                    <div className="card-body d-flex flex-column">*/}
            {/*                        <h5 className="card-title text-center">{t('professional')}</h5>*/}
            {/*                        <h6 className="card-subtitle text-center mb-4 text-muted">{t('professional_package_description')}</h6>*/}
            {/*                        <ul className="list-unstyled mb-4 flex-grow-1">*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('multiple_user')}*/}
            {/*                            </li>*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('contact_24_7')}*/}
            {/*                            </li>*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('carbon_emission_analysis')}*/}
            {/*                            </li>*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('database_api_access')}*/}
            {/*                            </li>*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('comparative_analysis')}*/}
            {/*                            </li>*/}
            {/*                            <hr />*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('low_carbon_design_guidance')}*/}
            {/*                            </li>*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('choosing_right_material')}*/}
            {/*                            </li>*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('energy_intensity_calculating')}*/}
            {/*                            </li>*/}
            {/*                            <li className="mb-2">*/}
            {/*                                <i className="bi bi-check-circle-fill secondary-text" /> {t('modelling_integration')}*/}
            {/*                            </li>*/}
            {/*                        </ul>*/}
            {/*                        <button*/}
            {/*                            className="btn secondary-text rounded-0 fw-bold"*/}
            {/*                            onClick={() => navigate("/sign-up")}*/}
            {/*                        >*/}
            {/*                            {t('join_now')} &nbsp;&nbsp;&nbsp;<i className="bi bi-arrow-right" />*/}
            {/*                        </button>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
        </>
    );
}

export default ArchitectAndEngineer;