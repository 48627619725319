import { useState } from "react";
import { forgotPasswordRequest } from "../../../api/auth";
import { useNavigate } from "react-router-dom";
import { NotificationService } from "../../../helper/notification";
import { useTranslation } from "react-i18next";
import {Spinner} from "react-bootstrap";

const ForgotPasswordRequest = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleForgetPassword = async () => {
    try {
      setLoading(true);
      await forgotPasswordRequest(email);

      navigate("/reset-password/" + email);
      NotificationService.success(
          t("forgotPassword.emailSentNotification")
      );
      setLoading(false)
    } catch (error) {
      console.log("Hata oluştu");
      setError(t("forgotPassword.error"));
    }
  };

  return (
      <section className="pb-5">
        <div className="bg-auth">
          <div className="w-50 p-5">
            <div className="p-5">
              <div className="p-5">
                <div className="py-5"></div>
                <div>
                  <h2 className="text-dark mb-3">{t("forgotPassword.title")}</h2>
                  <p className="text-dark pb-4">{t("forgotPassword.description")}</p>
                  {error && <div className="alert alert-danger">{error}</div>}
                  <div className="form-group">
                    <div className="mb-3">
                      <input
                          type="email"
                          onChange={(event) => setEmail(event.target.value)}
                          className="form-control shadow-none"
                          id="email"
                          placeholder={t("forgotPassword.emailPlaceholder")}
                      />
                      <small id="emailHelp" className="form-text text-muted">
                        {t("forgotPassword.emailHelp")}
                      </small>
                    </div>
                    <button
                        className="btn secondary-btn w-100 mt-3 mb-2"
                        onClick={handleForgetPassword}
                        disabled={loading}
                    >
                      {loading ? <Spinner animation="border" size="sm" /> : t("forgotPassword.buttonText")} {/* Spinner */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default ForgotPasswordRequest;
