import { useState, useEffect } from "react";
import {
  addProjectConstruction,
  deleteProjectConstruction,
  getProjectConstructions,
  updateProjectConstructions,
} from "../../../api/construction";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useParams, useLocation } from "react-router-dom";
import "./ProjectDetails.css";
import { getMaterialDataInfo } from "../../../api/materialData";
import ImportFromExcelButton from "../../../components/ImportFromExcelButton/ImportFromExcelButton";
import { NotificationService } from "../../../helper/notification";
import ShareButton from "../../../components/ProjectParticipationShareButton/ProjectParticipationShareButton";
import { getUserDetailed } from "../../../api/auth";
import ImportFromRevitButton from "../../../components/ImportFromRevitButton/ImportFromRevitButton";
import {useTranslation} from "react-i18next";
import {Spinner} from "react-bootstrap";

export const ProjectDetails = () => {
  const [user, setUser] = useState("");
  const { projectId } = useParams();
  const location = useLocation();
  const projectName = location.state?.name || "Project Details";
  const [constructions, setConstructions] = useState();
  const [materialData, setMaterialData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [constructionData, setConstructionData] = useState({
    name: "",
    createMaterialRequests: [],
  });
  const [formData, setFormData] = useState([
    { buildingComponent: "", type: "", quantity: 0 },
  ]);
  const [activeTab, setActiveTab] = useState("Cephe");
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isCopyCreateModalOpen, setIsCopyCreateModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [updateId, setUpdateId] = useState(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isFailedModalOpen, setIsFailedModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [failedModalBody, setFailedModalBody] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(2);

  const getConstructionList = async () => {
    try {
      const response = await getProjectConstructions(projectId);
      setConstructions(response.data);
    } catch (error) {
      NotificationService.error("An error occurred");
    }
  };

  useEffect(() => {
    const getMaterialData = async () => {
      try {
        const userData = await getUserDetailed();
        setUser(userData);
        const response = await getMaterialDataInfo();
        setMaterialData(response.data.materialDataInfoMap);
      } catch (error) {
        NotificationService.error("An error occurred");
      }
    };


    getConstructionList();
    getMaterialData();
  }, []);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Loading başlangıcı

    try {
      const response = await addProjectConstruction(projectId, constructionData);
      NotificationService.success("Project construction added successfully!");
      window.location.reload();
    } catch (error) {
      NotificationService.error("An error occurred");
    } finally {
      setLoading(false);
      setIsAddModalOpen(false);
    }
  };

  const handleCopyCreate = async (e) => {
    setLoading(true)
    e.preventDefault();
    try {
      await addProjectConstruction(
          projectId,
          constructionData
      );
      setLoading(false)
      window.location.reload();
    } catch (error) {
      NotificationService.error("An error occurred");
    }
    setIsCopyCreateModalOpen(false);
  };

  const handleUpdate = async (e) => {
    setLoading(true)
    e.preventDefault();
    try {
      await updateProjectConstructions(
          projectId,
          constructions[updateId].id,
          constructionData
      );
      setLoading(false)
      window.location.reload();
    } catch (error) {
      NotificationService.error("An error occurred");
    }
    setIsUpdateModalOpen(false);
  };

  const handleDelete = async (e) => {
    setLoading(true)
    e.preventDefault();
    try {
      await deleteProjectConstruction(projectId, constructions[updateId].id);
      setLoading(false)
      window.location.reload();
      NotificationService.success(
          "You have succesfully deleted the construction"
      );
    } catch (error) {
      NotificationService.error("An error occurred");
    }
    setIsUpdateModalOpen(false);
  };

  const addFormItem = (buildingComponent) => {
    setFormData([...formData, { buildingComponent: buildingComponent, type: "", quantity: 0 }]);
  };

  const deleteFormItem = (index) => {
    setFormData((prevFormData) => {
      const updatedFormData = prevFormData.filter((_, i) => i !== index);
      setConstructionData((prevConstructionData) => ({
        ...prevConstructionData,
        createMaterialRequests: updatedFormData,
      }));
      return updatedFormData;
    });
  };

  const isSaveButtonDisabled = () => {
    const requiredComponentsMet = Object.keys(materialData).every(buildingComponent =>
        formData.some(item => item.buildingComponent === buildingComponent && item.type !== "" && item.quantity > 0)
    );

    return (
        constructionData.name === "" ||
        !requiredComponentsMet
    );
  };


  const openAddModal = () => {
    setIsAddModalOpen(true);
    setConstructionData({
      name: "",
      createMaterialRequests: [
        {
          buildingComponent: "Cephe",
          type: "",
          quantity: 0
        },
        {
          buildingComponent: "Çatı",
          type: "",
          quantity: 0
        },
        {
          buildingComponent: "Temel",
          type: "",
          quantity: 0
        },
      ],
    });

    setFormData([
          {
            buildingComponent: "Cephe",
            type: "",
            quantity: 0
          },
          {
            buildingComponent: "Çatı",
            type: "",
            quantity: 0
          },
          {
            buildingComponent: "Temel",
            type: "",
            quantity: 0
          },
        ]
    );
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };

  const openCopyCreateModal = (id) => {
    setIsCopyCreateModalOpen(true);
    setUpdateId(id);
    const construction = constructions[id];
    setConstructionData({
      name: construction.name + "(Clone)",
      createMaterialRequests: construction.materials.map((material) => ({
        buildingComponent: material.buildingComponent,
        type: material.type,
        quantity: material.quantity
      }))
    });
    setFormData(construction.materials.map((material) => ({
      buildingComponent: material.buildingComponent,
      type: material.type,
      quantity: material.quantity
    })));
  }

  const openUpdateModal = (id) => {
    setIsUpdateModalOpen(true);
    setUpdateId(id);
    const construction = constructions[id];
    setConstructionData({
      name: construction.name,
      createMaterialRequests: construction.materials.map((material) => ({
        buildingComponent: material.buildingComponent,
        type: material.type,
        quantity: material.quantity
      }))
    });
    setFormData(construction.materials.map((material) => ({
      buildingComponent: material.buildingComponent,
      type: material.type,
      quantity: material.quantity
    })));
  };

  const closeCopyCreateModal = () => {
    setIsCopyCreateModalOpen(false);
  };

  const closeUpdateModal = () => {
    setIsUpdateModalOpen(false);
  };

  const openDeleteModal = (id) => {
    setIsDeleteModalOpen(true);
    setUpdateId(id);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const openFailedModal = () => {
    setIsFailedModalOpen(true);
  }

  const closeFailedModal = () => {
    setIsFailedModalOpen(false);
  }

  const setFailedModal = (failures) => {
    if (failures === null)
      return;

    openFailedModal();
    setFailedModalBody(failures.map(failuredMaterial => <p>Family: {failuredMaterial.buildingComponent} | Material Name:  {failuredMaterial.type}  |  Schedule:  {failuredMaterial.quantity}<br /></p>));
  }


  const handleInputChange = (index, field, value) => {
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[index][field] = value;
      setConstructionData((prevConstructionData) => ({
        ...prevConstructionData,
        createMaterialRequests: updatedFormData,
      }));
      return updatedFormData;
    });
  };

  const handleConstructionChange = (value) => {
    setConstructionData((prevConstructionData) => ({
      ...prevConstructionData,
      name: value,
      createMaterialRequests: formData,
    }));
  };

  const handleCheckboxChange = (event, construction) => {
    if (construction.materials.length > 0) {
      if (event.target.checked) {
        setSelectedIds([...selectedIds, construction.id]);
      } else {
        setSelectedIds(
            selectedIds.filter((selectedId) => selectedId !== construction.id)
        );
      }
    } else {
      NotificationService.error(
          "In order to choose a building its MaterialCount should be bigger than 0"
      );
    }
  };

  const indexOfLastConstruction = currentPage * itemsPerPage;
  const indexOfFirstConstruction = indexOfLastConstruction - itemsPerPage;
  const currentConstructions = constructions === undefined ? null : constructions.slice(indexOfFirstConstruction, indexOfLastConstruction);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(constructions === undefined ? null : constructions.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(currentPage + pageNumber);
  };

  const handleItemPerPageChange = (itemCount) => {
    setItemsPerPage(itemCount);
  }

  const {t, i18n} = useTranslation();

  return (
      <>
        <div className="py-5"></div>
        <section>
          <div className="container mt-5">
            <div className="d-flex justify-content-between align-items-center mb-5">
              <h2 className="text-dark">{t('project_details')}</h2>
              <div className="d-flex justify-content-end">
                <button
                    type="button"
                    className="btn secondary-btn fw-bold mx-1 my-3 px-3 py-2"
                    onClick={openAddModal}
                >
                  {t('add_new_building')} <i className="bi bi-plus"></i>
                </button>
                {user && (user?.role.permissions?.some(permission => permission.id === 16 || permission.id === 17)
                    || user?.subscriptionPlan.subscriptionPlanInfo.permissions?.some(permission => permission.id === 16 || permission.id === 17))
                    ? (
                        <Link
                            to={
                              selectedIds.length > 1
                                  ? `/projects/${projectId}/constructions/${selectedIds}`
                                  : "#"
                            }
                        >
                          <button
                              className="btn btn-primary me-3 fw-bold mx-1 my-3 px-3 py-2"
                              disabled={selectedIds.length < 2}
                          >
                            {t('compare_analyze')}
                          </button>
                        </Link>
                    ) : (<></>)}

                {user && ((user?.role.permissions?.some(permission => permission.id === 13)
                        || user?.subscriptionPlan.subscriptionPlanInfo.permissions?.some(permission => permission.id === 13)) &&
                    user.projectMemberships.some(project => project.projectAccessType == "OWNER" && project.project.id == projectId)) ? (<ShareButton projectId={projectId} />) : (<></>)
                }

              </div>
            </div>
            <div className="projects">
              {currentConstructions == null ? (
                  <div className="mt-5 d-flex justify-content-center align-items-center">
                    <ClipLoader
                        loading={currentConstructions === null}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                  </div>
              ) : currentConstructions.length === 0 ? (
                  <div className="alert alert-info" role="alert">
                    {t('no_building')}
                  </div>
              ) : (
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                      <tr>
                        <th scope="col">{t('selection')}</th>
                        <th scope="col">{t('building_name')}</th>
                        <th scope="col">{t('material_count')}</th>
                        <th scope="col">{t('last_updated_date')}</th>
                        <th scope="col">{t('actions')}</th>
                      </tr>
                      </thead>
                      <tbody>
                      {currentConstructions.map((construction, index) => (
                          <tr key={index}>
                            <th>
                              <input
                                  className="form-check-input"
                                  type="checkbox"
                                  onChange={(event) =>
                                      handleCheckboxChange(event, construction)
                                  }
                                  checked={selectedIds.includes(construction.id)}
                                  value=""
                                  id={construction.id}
                              />
                            </th>
                            <td>{construction.name}</td>
                            <td>{construction.materials?.length}</td>
                            <td>{construction.lastUpdateDate}</td>
                            <td>
                              <i
                                  onClick={() => openCopyCreateModal((currentPage - 1) * itemsPerPage + index)}
                                  class="bi bi-file-earmark-plus text-info pe-2"></i>
                              <i
                                  onClick={() => openUpdateModal((currentPage - 1) * itemsPerPage + index)}
                                  className="bi bi-pencil-square text-success pe-2"
                              ></i>
                              <i
                                  onClick={() => openDeleteModal((currentPage - 1) * itemsPerPage + index)}
                                  className="bi bi-trash text-danger pe-2"
                              ></i>
                              <Link
                                  to={
                                    construction.materials?.length > 0
                                        ? `/projects/${projectId}/construction/${construction.id}`
                                        : `#`
                                  }
                                  onClick={() => {
                                    if (construction.materials?.length > 0) {
                                    } else {
                                      NotificationService.error(
                                          "In order to analyze a building its MaterialCount should be bigger than 0"
                                      );
                                    }
                                  }}
                                  key={index}
                              >
                                <i
                                    className={
                                      construction.materials?.length > 0
                                          ? "bi bi-graph-up"
                                          : "bi bi-graph-up text-muted"
                                    }
                                ></i>
                              </Link>
                            </td>
                          </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
              )}
            </div>
            <div className="pagination d-flex justify-content-center py-5">
              <div className="px-3">
                <div className="input-group">
                  <label className="px-2 py-2">{t('items_per_page')}</label>
                  <select
                      className="form-select border-0 border-2 border-bottom border-dark"
                      value={itemsPerPage}
                      onChange={(event) => { handleItemPerPageChange(event.target.value) }}
                  >
                    <option value={2}>2</option>
                    <option value={4}>4</option>
                    <option value={6}>6</option>
                    <option value={8}>8</option>
                    <option value={10}>10</option>
                  </select>
                  <hr />
                </div>
              </div>
              <div className="px-3 py-2">{indexOfFirstConstruction + 1} - {indexOfLastConstruction} &nbsp; of &nbsp; {constructions === undefined ? null : constructions.length}</div>
              <button
                  className="btn border-0 mx-3"
                  onClick={() => handlePageChange(-1)}
                  disabled={currentPage <= 1}>
                <i className="bi bi-arrow-left"></i></button>
              <button
                  className="btn border-0 mx-3"
                  onClick={() => handlePageChange(1)}
                  disabled={currentPage >= (constructions === undefined ? null : constructions.length / itemsPerPage)}>
                <i className="bi bi-arrow-right"></i></button>
            </div>

            {isAddModalOpen && (
                <div
                    className="modal fade show d-block"
                    id="addConstructionModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="addConstructionModalLabel"
                    aria-hidden="true"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                >
                  <div
                      className="modal-dialog modal-dialog-scrollable modal-dialog-centered"
                      role="document"
                      style={{ maxWidth: "800px" }}
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1 className="modal-title fs-5" id="addConstructionModalLabel">
                          Create Building
                        </h1>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={closeAddModal}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="container mt-2">
                          <div className="d-flex mb-3">
                            <label htmlFor="name" className="form-label">
                              {t('building_name')}:
                            </label>
                            <input
                                id="name"
                                type="text"
                                className="form-control"
                                value={constructionData.name}
                                onChange={(event) => {
                                  handleConstructionChange(event.target.value);
                                  event.preventDefault();
                                }}
                            />
                          </div>
                          {materialData && (
                              <div>
                                <ul className="nav nav-tabs">
                                  {Object.keys(materialData).map((buildingComponent, idx) => (
                                      <li className="nav-item" key={idx}>
                                        <a
                                            className={`nav-link ${activeTab === buildingComponent ? "active" : ""}`}
                                            onClick={() => handleTabClick(buildingComponent)}
                                        >
                                          {buildingComponent}
                                        </a>
                                      </li>
                                  ))}
                                </ul>
                                <div className="tab-content mt-3">
                                  {Object.keys(materialData).map((buildingComponent, idx) => (
                                      <div
                                          className={`tab-pane fade ${activeTab === buildingComponent ? "show active" : ""}`}
                                          key={idx}
                                      >
                                        {formData.map((item, index) =>
                                                item.buildingComponent === buildingComponent && (
                                                    <div key={index} className="row mb-3">
                                                      <div className="col">
                                                        <label htmlFor={`type-${index}`} className="form-label">
                                                          {t('material_name')}:
                                                        </label>
                                                        <select
                                                            id={`type-${index}`}
                                                            className="form-select"
                                                            value={item.type}
                                                            onChange={(event) => handleInputChange(index, "type", event.target.value)}
                                                        >
                                                          <option value="">Select</option>
                                                          {materialData &&
                                                              materialData[buildingComponent]?.map(
                                                                  (type) => <option value={type.name}>{type.name}</option>
                                                              )}
                                                        </select>
                                                      </div>
                                                      <div className="col">
                                                        <label
                                                            htmlFor={`quantity-${index}`}
                                                            className="form-label"
                                                        >
                                                          Schedule:
                                                        </label>
                                                        <div className="input-group">
                                                          <input
                                                              id={`quantity-${index}`}
                                                              type="number"
                                                              className="form-control"
                                                              value={item.quantity}
                                                              onChange={(event) =>
                                                                  handleInputChange(
                                                                      index,
                                                                      "quantity",
                                                                      event.target.value
                                                                  )
                                                              }
                                                              disabled={item.type === ""}
                                                          />
                                                          <div className="input-group-append">
                                                            {materialData &&
                                                            materialData[item.buildingComponent]?.find(component => component.name === item.type)?.unit ? (
                                                                <span
                                                                    className="input-group-text"
                                                                    dangerouslySetInnerHTML={{
                                                                      __html: materialData[
                                                                          item.buildingComponent
                                                                          ].find(component => component.name === item.type).unit.replace(/(\d)/, "<sup>$1</sup>"),
                                                                    }}
                                                                />
                                                            ) : (
                                                                <span
                                                                    className="input-group-text"
                                                                    dangerouslySetInnerHTML={{
                                                                      __html: "&nbsp;",
                                                                    }}
                                                                />
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="col-sm-1 d-flex align-items-end justify-content-end">
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger"
                                                            onClick={() => deleteFormItem(index)}
                                                        >
                                                          <i className="bi bi-trash"></i>
                                                        </button>
                                                      </div>
                                                    </div>
                                                )
                                        )}
                                        <div className="d-flex justify-content-end pb-2 gap-3">
                                          <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => addFormItem(buildingComponent)}
                                          >
                                            <i className="bi bi-plus-circle"></i>
                                          </button>
                                        </div>
                                      </div>
                                  ))}
                                </div>
                              </div>
                          )}
                          <hr />
                          <div className="d-flex justify-content-end pb-2 gap-3">
                            {materialData && (
                                <>
                                  <ImportFromRevitButton
                                      setFormData={setFormData}
                                      setConstructionData={setConstructionData}
                                      setFailedModal={setFailedModal}
                                  />
                                  <ImportFromExcelButton
                                      setFormData={setFormData}
                                      setConstructionData={setConstructionData}
                                      materialData={materialData}
                                  />
                                </>
                            )}
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={closeAddModal}
                          >
                            Close
                          </button>
                          <button
                              type="submit"
                              className="btn secondary-btn"
                              disabled={isSaveButtonDisabled() || loading}
                              onClick={handleSubmit}
                          >
                            {loading ? <Spinner animation="border" size="sm" /> : "Save Building"} {/* Spinner */}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            )}
            {isCopyCreateModalOpen && (
                <div
                    className="modal fade show d-block"
                    id="copyCreateConstructionModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="copyCreateConstructionModalLabel"
                    aria-hidden="true"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                >
                  <div
                      className="modal-dialog modal-dialog-scrollable modal-dialog-centered"
                      role="document"
                      style={{ maxWidth: "900px" }}
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1 className="modal-title fs-5" id="copyCreateConstructionModalLabel">
                          Clone {constructions[updateId].name}
                        </h1>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={closeCopyCreateModal}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="container mt-2">
                          <div className="d-flex mb-3">
                            <label htmlFor="name" className="form-label">
                              Building Name:
                            </label>
                            <input
                                id="name"
                                type="text"
                                className="form-control"
                                value={constructionData.name}
                                onChange={(event) => {
                                  handleConstructionChange(event.target.value);
                                }}
                            />
                          </div>
                          {materialData && (
                              <div>
                                <ul className="nav nav-tabs">
                                  {Object.keys(materialData).map((buildingComponent, idx) => (
                                      <li className="nav-item" key={idx}>
                                        <a
                                            className={`nav-link ${activeTab === buildingComponent ? "active" : ""}`}
                                            onClick={() => handleTabClick(buildingComponent)}
                                        >
                                          {buildingComponent}
                                        </a>
                                      </li>
                                  ))}
                                </ul>
                                <div className="tab-content mt-3">
                                  {Object.keys(materialData).map((buildingComponent, idx) => (
                                      <div
                                          className={`tab-pane fade ${activeTab === buildingComponent ? "show active" : ""}`}
                                          key={idx}
                                      >
                                        {formData.map((item, index) =>
                                                item.buildingComponent === buildingComponent && (
                                                    <div key={index} className="row mb-3">
                                                      <div className="col">
                                                        <label htmlFor={`type-${index}`} className="form-label">
                                                          Material Name:
                                                        </label>
                                                        <select
                                                            id={`type-${index}`}
                                                            className="form-select"
                                                            value={item.type}
                                                            onChange={(event) => handleInputChange(index, "type", event.target.value)}
                                                        >
                                                          <option value="">Select</option>
                                                          {materialData[buildingComponent]?.map(
                                                              (type) => <option key={type.name} value={type.name}>{type.name}</option>
                                                          )}
                                                        </select>
                                                      </div>
                                                      <div className="col">
                                                        <label
                                                            htmlFor={`quantity-${index}`}
                                                            className="form-label"
                                                        >
                                                          Schedule:
                                                        </label>
                                                        <div className="input-group">
                                                          <input
                                                              id={`quantity-${index}`}
                                                              type="number"
                                                              className="form-control"
                                                              value={item.quantity}
                                                              onChange={(event) =>
                                                                  handleInputChange(index, "quantity", event.target.value)
                                                              }
                                                              disabled={item.type === ""}
                                                          />
                                                          <div className="input-group-append">
                                                            {materialData[item.buildingComponent]?.find(component => component.name === item.type)?.unit ? (
                                                                <span
                                                                    className="input-group-text"
                                                                    dangerouslySetInnerHTML={{
                                                                      __html: materialData[item.buildingComponent].find(component => component.name === item.type).unit.replace(/(\d)/, "<sup>$1</sup>"),
                                                                    }}
                                                                />
                                                            ) : (
                                                                <span
                                                                    className="input-group-text"
                                                                    dangerouslySetInnerHTML={{
                                                                      __html: "&nbsp;",
                                                                    }}
                                                                />
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="col-sm-1 d-flex align-items-end justify-content-end">
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger"
                                                            onClick={() => deleteFormItem(index)}
                                                        >
                                                          <i className="bi bi-trash"></i>
                                                        </button>
                                                      </div>
                                                    </div>
                                                )
                                        )}
                                        <div className="d-flex justify-content-end pb-2 gap-3">
                                          <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => addFormItem(buildingComponent)}
                                          >
                                            <i className="bi bi-plus-circle"></i>
                                          </button>
                                        </div>
                                      </div>
                                  ))}
                                </div>
                              </div>
                          )}
                        </div>
                        <div className="modal-footer">
                          <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={closeCopyCreateModal}
                          >
                            Close
                          </button>
                          <button
                              type="submit"
                              className="btn btn-success"
                              disabled={isSaveButtonDisabled() || loading}
                              onClick={handleCopyCreate}
                          >
                            {loading ? <Spinner animation="border" size="sm" /> : "Save Building"} {/* Spinner */}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            )}
            {isUpdateModalOpen && (
                <div
                    className="modal fade show d-block"
                    id="updateConstructionModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="updateConstructionModalLabel"
                    aria-hidden="true"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                >
                  <div
                      className="modal-dialog modal-dialog-scrollable modal-dialog-centered"
                      role="document"
                      style={{ maxWidth: "900px" }}
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1 className="modal-title fs-5" id="updateConstructionModalLabel">
                          Update Building: {constructions[updateId].name}
                        </h1>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={closeUpdateModal}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="container mt-2">
                          <div className="d-flex mb-3">
                            <label htmlFor="name" className="form-label">
                              Building Name:
                            </label>
                            <input
                                id="name"
                                type="text"
                                className="form-control"
                                value={constructionData.name}
                                onChange={(event) => {
                                  handleConstructionChange(event.target.value);
                                }}
                            />
                          </div>
                          {materialData && (
                              <div>
                                <ul className="nav nav-tabs">
                                  {Object.keys(materialData).map((buildingComponent, idx) => (
                                      <li className="nav-item" key={idx}>
                                        <a
                                            className={`nav-link ${activeTab === buildingComponent ? "active" : ""}`}
                                            onClick={() => handleTabClick(buildingComponent)}
                                        >
                                          {buildingComponent}
                                        </a>
                                      </li>
                                  ))}
                                </ul>
                                <div className="tab-content mt-3">
                                  {Object.keys(materialData).map((buildingComponent, idx) => (
                                      <div
                                          className={`tab-pane fade ${activeTab === buildingComponent ? "show active" : ""}`}
                                          key={idx}
                                      >
                                        {formData.map((item, index) =>
                                                item.buildingComponent === buildingComponent && (
                                                    <div key={index} className="row mb-3">
                                                      <div className="col">
                                                        <label htmlFor={`type-${index}`} className="form-label">
                                                          Material Name:
                                                        </label>
                                                        <select
                                                            id={`type-${index}`}
                                                            className="form-select"
                                                            value={item.type}
                                                            onChange={(event) => handleInputChange(index, "type", event.target.value)}
                                                        >
                                                          <option value="">Select</option>
                                                          {materialData[buildingComponent]?.map(
                                                              (type) => <option key={type.name} value={type.name}>{type.name}</option>
                                                          )}
                                                        </select>
                                                      </div>
                                                      <div className="col">
                                                        <label
                                                            htmlFor={`quantity-${index}`}
                                                            className="form-label"
                                                        >
                                                          Schedule:
                                                        </label>
                                                        <div className="input-group">
                                                          <input
                                                              id={`quantity-${index}`}
                                                              type="number"
                                                              className="form-control"
                                                              value={item.quantity}
                                                              onChange={(event) =>
                                                                  handleInputChange(index, "quantity", event.target.value)
                                                              }
                                                              disabled={item.type === ""}
                                                          />
                                                          <div className="input-group-append">
                                                            {materialData[item.buildingComponent]?.find(component => component.name === item.type)?.unit ? (
                                                                <span
                                                                    className="input-group-text"
                                                                    dangerouslySetInnerHTML={{
                                                                      __html: materialData[item.buildingComponent].find(component => component.name === item.type).unit.replace(/(\d)/, "<sup>$1</sup>"),
                                                                    }}
                                                                />
                                                            ) : (
                                                                <span
                                                                    className="input-group-text"
                                                                    dangerouslySetInnerHTML={{
                                                                      __html: "&nbsp;",
                                                                    }}
                                                                />
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="col-sm-1 d-flex align-items-end justify-content-end">
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger"
                                                            onClick={() => deleteFormItem(index)}
                                                        >
                                                          <i className="bi bi-trash"></i>
                                                        </button>
                                                      </div>
                                                    </div>
                                                )
                                        )}
                                        <div className="d-flex justify-content-end pb-2 gap-3">
                                          <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => addFormItem(buildingComponent)}
                                          >
                                            <i className="bi bi-plus-circle"></i>
                                          </button>
                                        </div>
                                      </div>
                                  ))}
                                </div>
                              </div>
                          )}
                        </div>
                        <div className="modal-footer">
                          <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={closeUpdateModal}
                          >
                            Close
                          </button>
                          <button
                              type="submit"
                              className="btn btn-success"
                              disabled={isSaveButtonDisabled() || loading}
                              onClick={handleUpdate}
                          >
                            {loading ? <Spinner animation="border" size="sm" /> : "Save Changes"} {/* Spinner */}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            )}
            {isDeleteModalOpen && (
                <div className="modal fade show d-block" tabIndex="-1" role="dialog">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          {t('delete')} {constructions[updateId].name}
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={closeDeleteModal}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <p>{t('delete_description')}</p>
                      </div>
                      <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={closeDeleteModal}
                            disabled={loading}
                        >
                          {t('cancel')}
                        </button>
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={handleDelete}
                            disabled={loading}
                        >
                          {loading ? <Spinner animation="border" size="sm" /> : t("delete")} {/* Spinner */}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
            )}
            {isFailedModalOpen && (
                <div class="modal fade show d-block" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-scrollable modal-lg">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">Thoese listed materials failured to convert:</h1>
                        <button type="button" class="btn-close" onClick={closeFailedModal} aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        {failedModalBody}
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" onClick={closeFailedModal}>Close</button>
                      </div>
                    </div>
                  </div>
                </div>
            )}
          </div>
        </section>
      </>
  );
};
