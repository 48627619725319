import { Banner } from "../../../components/LandingPageComponents/Banner/Banner";
import "./Background.css";
import image_1 from "./partnership-image-2.webp";
import image_1_tr from "./partnership-image-2-tr.webp";
import icon_1 from "./partnership-icon-1.webp";
import { InsideAction } from "../../../components/LandingPageComponents/InsideAction/InsideAction";
import {useTranslation} from "react-i18next";

const logos = [
    "https://seeklogo.com/images/V/vitra-logo-41E765F326-seeklogo.com.png",
    "https://ceramicconcept.com/wp-content/uploads/elementor/thumbs/logo-quaseramik-pvk9exj3gli7lbez3d4reoo79tluj0g0fxjndjygow.png",
    "https://www.logovector.org/wp-content/uploads/logos/png/k/kale_seramik_logo.png",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9rgl9qPFTaabzWjMzkXiOACwxt1tovkqTbw&s",
    "https://kouzaris.com/wp-content/uploads/2021/04/logo-mobile-1.png",
    "https://www.fum.it/wp-content/uploads/2021/03/FRANCHI-UMBERTO-MARMI_logo-orizzontale-nero.png",
    "https://s3.eu-central-1.amazonaws.com/stajim/media/images/internship/image/3029_20220425173557.jpg",
    "https://saray.com/wp-content/uploads/2018/04/saray-aluminyum-yatay.png",
    "https://www.kme.com/fileadmin/DOWNLOADCENTER/KME_LOGOS/SCREEN/2019/logo_KME_2019_sw_.png",
    "https://www.cmetals.com/wp-content/uploads/2023/06/Rheinzink_LOGO_grey.png",
    "https://logowik.com/content/uploads/images/baumit6022.jpg",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZWwvxq8ccwKn6xtkW6XH0TsgVu7gJe4Lygw&s",
    "https://www.yapimarka.com/resimler/logolar/Kalekim-441.jpg",
    "https://seekvectors.com/files/download/jotun-black.png",
    "https://www.polisanholding.com/Content/SayfaIcerik/Logo_PolisanHomeCosmetics_77d88.jpeg",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/6/67/DYO_turkey_Logo.svg/800px-DYO_turkey_Logo.svg.png",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeN37kuwplgsYpIQUuoFB5vUpnI1Zm77MQjg&s",
    "https://www.gentas.com.tr/wp-content/uploads/2021/04/logo.png",
];

const LogoSlider = () => {
    return (
        <div className="logo-slider">
            <div className="logo-track">
                <div className="logo-track-inner">
                    {logos.map((logo, index) => (
                        <div className="logo" key={index}>
                            <img
                                src={logo}
                                alt={`Logo ${index + 1}`}
                                onError={(e) => (e.target.style.display = "none")}
                            />
                        </div>
                    ))}
                    {logos.map((logo, index) => (
                        <div className="logo" key={index + logos.length}>
                            <img
                                src={logo}
                                alt={`Logo ${index + 1}`}
                                onError={(e) => (e.target.style.display = "none")}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};



const Partnership = () => {

    const {t, i18n} = useTranslation();

    return (
        <>
            <section>
                <Banner startPage={"about-us"} page={"partnership"}></Banner>
            </section>
            <section className="pb-5">
                <div className="container">

                </div>
                <div className="row align-items-center justify-content-center ">
                    {i18n.language === "en" ? (
                        <img className="col-md-6" src={image_1} width={"100%"}/>


                    ) : <img className="col-md-6" src={image_1_tr} width={"100%"}/>}
                    <div className="col-md-6">
                    <h1 className=" secondary-text pb-3">{t('Our Partnership')}</h1>
                        <p className="fs-3">{t('Our partnerships drive innovation and sustainability, enabling us to reduce carbon emissions and transform the construction in residences together.')}</p>
                    </div>
                </div>
            </section>
            <section className="py-5">
                <div className="pb-5">
                    <h2 className="text-center secondary-text">{t('Trusted by More than 100 Companies')}</h2>
                </div>
                <div className="py-5" style={{ filter: "grayscale(100%)" }}>
                    <LogoSlider />
                </div>
            </section>
            <section className="py-5">
                <InsideAction />
            </section>
        </>
    )
}

export default Partnership;