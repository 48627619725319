import { Banner } from "../../../components/LandingPageComponents/Banner/Banner";
import { InsideAction } from "../../../components/LandingPageComponents/InsideAction/InsideAction";
import "./Background.css";
import resources_image_3 from "./resources-image-3.webp";
import resources_image_3_tr from "./resources-image-3-tr.webp";
import resources_image_4 from "./resources-image-4.webp";
import resources_image_4_tr from "./resources-image-4-tr.webp";
import resources_image_5 from "./resources-image-5.webp";
import resources_image_5_tr from "./resources-image-5-tr.webp";
import resources_image_6 from "./resources-image-6.webp";
import resources_image_7 from "./resources-image-7.webp";
import {useTranslation} from "react-i18next";


const Resources = () => {
    const {t, i18n} = useTranslation();

    return (
        <>
            <section>
                <Banner page={"resources"} />
            </section>
            <section className="py-4 px-3">
                <div className="container">
                    <div>
                        <h2 className="ps-5 pb-3">{t('resourcess.title')}</h2>
                        <p className="col-6 ps-5">{t('resourcess.intro')}</p>
                    </div>
                </div>
                <div>
                    <h5 className="text-center secondary-text pb-3">{t('resourcess.articles')}</h5>
                    <h3 className="text-center pb-3">{t('resourcess.recentPosts')}</h3>
                    <div className="bg-resources-1">
                        <div className="d-flex justify-content-center">
                        </div>
                        <div className="container px-5">
                            <div className="row d-flex justify-content-center py-5">
                                {i18n.language === "en" ? (
                                    <div className={"row"}>
                                        <div className={"col-md-4"}>
                                            <a target="_blank"
                                               href="https://www.inanckabadayi.com.tr/Blog/sifir-karbon-emisyonu-icin-yeni-nesil-betondan-faydalanilabilir-mi">
                                                <img className=" card border-0 mx-3 mb-5"
                                                     src={resources_image_3}
                                                     width="100%" alt="Resource 3"/>
                                            </a>
                                        </div>
                                        <div className={"col-md-4"}>
                                            <a target="_blank"
                                               href="https://www.inanckabadayi.com.tr/Blog/dunyanin-farkli-bolgelerinde-sifir-karbon-sehir-projeleri-yukseliyor">
                                                <img className=" card border-0 mx-3 mb-5"
                                                     src={resources_image_4}
                                                     width="100%" alt="Resource 4"/>
                                            </a>
                                        </div>
                                        <div className={"col-md-4"}>

                                            <a target="_blank"
                                               href="https://www.inanckabadayi.com.tr/Blog/kentsel-donusum--mevcut-binalari-karbondan-arindirmanin-yollari-neler">
                                                <img className="card border-0 mx-3 mb-5"
                                                     src={resources_image_5}
                                                     width="100%" alt="Resource 5"/>
                                            </a>
                                        </div>


                                    </div>
                                ) : (
                                    <div className={"row"}>
                                        <div className={"col-md-4"}>
                                            <a target="_blank"
                                               href="https://www.inanckabadayi.com.tr/Blog/sifir-karbon-emisyonu-icin-yeni-nesil-betondan-faydalanilabilir-mi">
                                                <img className=" card border-0 mx-3 mb-5"
                                                     src={resources_image_3_tr}
                                                     width="100%" alt="Resource 3"/>
                                            </a>
                                        </div>
                                        <div className={"col-md-4"}>
                                            <a target="_blank"
                                               href="https://www.inanckabadayi.com.tr/Blog/dunyanin-farkli-bolgelerinde-sifir-karbon-sehir-projeleri-yukseliyor">
                                                <img className=" card border-0 mx-3 mb-5"
                                                     src={resources_image_4_tr}
                                                     width="100%" alt="Resource 4"/>
                                            </a>
                                        </div>
                                        <div className={"col-md-4"}>

                                            <a target="_blank"
                                               href="https://www.inanckabadayi.com.tr/Blog/kentsel-donusum--mevcut-binalari-karbondan-arindirmanin-yollari-neler">
                                                <img className="card border-0 mx-3 mb-5"
                                                     src={resources_image_5_tr}
                                                     width="100%" alt="Resource 5"/>
                                            </a>
                                        </div>


                                    </div>

                        )}
                    </div>

                    <div className="d-flex justify-content-between py-5">
                        <div className="d-flex">
                            <button className="btn secondary-btn rounded-0 py-2" disabled={true}><i
                                className="bi bi-arrow-left"></i></button>
                            <button className="btn secondary-btn rounded-0 py-2"><i
                                className="bi bi-arrow-right"></i></button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
</section>
    <section className="py-3">
        <div className="container">
            <div className="row d-flex justify-content-between">
                <div className="col-md-4 mb-3">
                    <h2 className="pb-3">{t('resourcess.latestPress')}</h2>

                        </div>
                        <div className="col-md-3 mb-3">
                            <img className="pb-4" src={resources_image_6} width={"80%"} alt="Press Release 1"/>
                            <h5>{t('resourcess.inspiring.TuganaAkyurek.title')}</h5>
                            <p className="primary-text">{t('resourcess.inspiring.TuganaAkyurek.source')}</p>
                            <p>{t('resourcess.inspiring.TuganaAkyurek.description')}</p>
                            <h5 className="btn">{t('resourcess.viewNews')} &nbsp;&nbsp;&nbsp;<i
                                className="bi bi-arrow-right"></i></h5>
                        </div>
                        <div className="col-md-3 mb-3">
                            <img className="pb-4" src={resources_image_7} width={"80%"} alt="Press Release 2"/>
                            <h5>{t('resourcess.collective24.title')}</h5>
                            <p className="primary-text">{t('resourcess.collective24.source')}</p>
                            <p>{t('resourcess.collective24.description')}</p>
                            <h5 className="btn">{t('resourcess.viewNews')} &nbsp;&nbsp;&nbsp;<i
                                className="bi bi-arrow-right"></i></h5>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5">
                <InsideAction/>
            </section>
        </>);
}

export default Resources;