import React, { useEffect, useState } from 'react';
import { NotificationService } from '../../helper/notification';
import {getAllRoles, getAllRolesTR} from '../../api/admin/role';
import i18n from "../../i18n";
import {useTranslation} from "react-i18next";

const RoleSelection = ({ hasRole, nextStep }) => {
    const {t, i18n} = useTranslation();

    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);

    useEffect(() => {
        const getRoles = async () => {
            try {
                if(i18n.language === 'tr-TR') {
                    const roleData = await getAllRolesTR();
                    setRoles(roleData);
                }
                else {
                    const roleData = await getAllRoles();
                    setRoles(roleData);
                }
            } catch (error) {
                NotificationService.error("An error occured");
            }

            return roles;
        };

        if (hasRole !== null) {
            setSelectedRole(hasRole);
            nextStep({ role: hasRole });
        } else {
            getRoles();
        }
    }, []);

    const handleRoleChange = (role) => {
        setSelectedRole(role);
    };

    const handleNext = () => {
        if (selectedRole) {
            nextStep({ role: selectedRole });
        }
    };

    return (
        <div className="text-center">
            <h2 className={"mb-4"}>{t('select_your_role')}</h2>
            <div className="row justify-content-center">
                {roles.map((role) => (
                    role.id !== 1 ? (
                        <div className="col-lg-3 col-md-6 mb-4" key={role.id}>
                            <div
                                className={`card h-100 shadow-sm ${selectedRole === role.id ? 'border-success' : ''}`}
                                onClick={() => handleRoleChange(role.id)}
                            >
                                <div className="card-body">
                                    <h5 className="card-title">{role.name}</h5>
                                    <h6 className="card-subtitle mb-2 text-muted">{role.description}</h6>
                                    <hr />
                                    <ul className="list-unstyled mb-4">
                                        {role.features.map((feature, index) => (
                                            <li key={index}>
                                                <i className="bi bi-check-circle-fill text-success"></i> {feature}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>) : (<></>)
                ))}
            </div>
            <button className="btn btn-success mt-4" onClick={handleNext} disabled={!selectedRole}>
                Next
            </button>
        </div>
    );
};

export default RoleSelection;
