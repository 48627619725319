import { useState, useEffect } from "react";
import SubmitDataButton from "../../components/SubmitDataButton/SubmitDataButton";
import {
  createMaterialDataList,
  getMaterialDataInfo,
} from "../../api/materialData";
import { NotificationService } from "../../helper/notification";
import {useTranslation} from "react-i18next";

const DataSubmission = () => {
  const [materialData, setMaterialData] = useState(null);
  const [data, setData] = useState(null);
  const {t, i18n} = useTranslation();


  useEffect(() => {
    const getMaterialData = async () => {
      try {
        const response = await getMaterialDataInfo();

        setMaterialData(response.data.materialDataInfoMap);
      } catch (error) {
        NotificationService.error("An error occurred");
      }
    };

    getMaterialData();
  }, []);
  const handleSubmit = async () => {
    try {
      await createMaterialDataList(data);
      NotificationService.success(
        "You successfully make the request. We will review your request as soon as possible!"
      );
    } catch (error) {
      NotificationService.error("An error occurred!");
    }
  };

  return (
    <section>
      <div className="py-5"></div>
      <div className="container col-md-6 mt-5">
        <h3 className="py-4">{t('data_submission')}</h3>
        <div className="alert alert-info pb-4">
          {t('submit_data_description')}
        </div>
        <div>
          {materialData && (
            <SubmitDataButton materialData={materialData} setData={setData} />
          )}
          <button
            className="btn secondary-btn w-100 mt-5 mb-2 fw-bold mb-5"
            onClick={handleSubmit}
          >
            {t('submit_data')}
          </button>
        </div>
      </div>
    </section>
  );
};

export default DataSubmission;
