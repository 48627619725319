import {useRef, useState} from "react";
import { generateMaterialListFromCsv } from "../../api/materialData";
import {Spinner} from "react-bootstrap";

const ImportFromRevitButton = ({
    setFormData,
    setConstructionData,
    setFailedModal
}) => {
    const fileInputRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const handleFileInputClick = () => {
        fileInputRef.current.click();
    };

    const handleUpload = async (file) => {

        if (!file) return;
        const formData = new FormData();
        formData.append("file", file);

        try {
            setLoading(true)
            const response = await generateMaterialListFromCsv(formData);
            setFormData(response.convertedMaterialRequests);
            setConstructionData((prevConstructionData) => ({
                ...prevConstructionData,
                createMaterialRequests: response.convertedMaterialRequests,
            }));
            setFailedModal(response.conversionFailedMaterialRequests);
            setLoading(false)
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    return (
        <div>
            <input
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={(event) => handleUpload(event.target.files[0])}
            />
            <button className="btn secondary-btn" onClick={handleFileInputClick} disabled={loading}>
                {loading ? <Spinner animation="border" size="sm" /> : "Import from Revit"} {/* Spinner */}
            </button>

        </div>
    );
};

export default ImportFromRevitButton;
